
window.addEventListener("load", function () {
  const formContainers = document.getElementsByClassName("section-cv-form");
  //Check the form exists on the page
  if (formContainers.length > 0) {
    // add accept types to the input file
    const fileInput = document.querySelector(".section-cv-form input[type='file']");
    let formContainer = formContainers[0];
    let form = formContainer.getElementsByTagName("form")[0];
    let responseContainer = formContainer.getElementsByClassName("submit-response-container")[0];
    responseContainer.style.display = 'none';

    const checkFileExtension = (val) => {
      var regex = new RegExp("(.*?).(pdf|docx|doc|jpg|jpeg)$");
      if (!(regex.test(val))) {
        return false;
      }
      return true;
    }

    const button = document.querySelector(".section-cv-form .submitBtn button");

    fileInput.addEventListener("change", function (ev) {
      const fileError = document.getElementById("file-error");
      if (!checkFileExtension(ev.target.value)) {
        fileError.setAttribute("style", "display:block;");
      }
      else if (fileError) {
        fileError.removeAttribute("style");
      }
    });

    form.addEventListener('submit', function (evt) {
      evt.preventDefault();
      const fileError = document.getElementById("file-error");
      if (fileError)
        fileError.removeAttribute("style");

      if (!checkFileExtension(fileInput.value)) {
        // Show error
        fileError.setAttribute("display", "display:block;");
        return;
      }

      // disable submit
      button.setAttribute("class", "loading");
      button.setAttribute("disabled", true);

      var formData = new FormData(this);
      fetch('/umbraco/surface/CVUpload/Submit', { method: "POST", body: formData })
        .then((result) => {
          if (result.status != 200) { throw new Error("Could not upload file") }
          return result.text();
        })
        .then((response) => {
          form.style.display = 'none';
          responseContainer.style.display = 'block';
          let retVal = JSON.parse(response);
          responseContainer.getElementsByClassName("form-submit-response")[0].innerHTML = retVal.response;
          button.removeAttribute("class");
          button.removeAttribute("disabled");
        })
        .catch((error) => {
          button.removeAttribute("class");
          button.removeAttribute("disabled");
          console.log(error);
        });

    });
  }
});


window.GetQueryStringVariable = function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}


// Form Validation
const email = document.getElementById("cvuploadEmail");
if (email != null) {
  email.addEventListener("input", function () {

    if (email.validity.typeMismatch) {
      email.setCustomValidity("Please enter a valid email address");
    } else {
      email.setCustomValidity("");
    }
  });
}