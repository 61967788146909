window.addEventListener("load", function () {
  const banner = document.getElementById("osaka-bay-banner");
  const hideBanner = window.sessionStorage.getItem('hide-osaka-bay-banner')

  if (banner) {
    if (hideBanner) {
      banner.classList.remove('visible')
    }

    if (!hideBanner) {
      banner.classList.add('visible')
      const bannerCloseButton = document.getElementById("osaka-bay-banner-close");
      bannerCloseButton.addEventListener("click", () => {
        saveCookie();
        banner.style.display = 'none';
      })
    }

  }
});

const saveCookie = function () {
  const siteUrl = window.SD_SITE_URL;
  if (!siteUrl)
    return;
  return fetch(`${siteUrl}umbraco/api/cookies/SetBannerClosed`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
  }).then(() => {
    return true;
  })
}