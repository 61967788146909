
window.addEventListener('load', function(){
  const modals = document.getElementsByClassName('modal');

  const closeModalOnEscapeKey = function(e) {
    if (e.which === 27) {
      closeModal(this)
    }
  }
  const openModal = function(modal) {
    modal.setAttribute('show', true)
    window.document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', closeModalOnEscapeKey.bind(modal))
  }
  const closeModal = function(modal) {
    modal.setAttribute('show', false)
    window.document.body.style.overflow = null
    document.removeEventListener('keydown', closeModalOnEscapeKey.bind(modal))
  };


  if (modals) {
    [...modals].forEach((modal)=> {
      const id = modal.id;
      const openButtons = document.querySelectorAll(`[show-modal="${id}"]`);
      const closeButton = modal.querySelector('.close-button');
      const background = modal.querySelector(`.background`);

      [...openButtons].forEach((openButton)=> {
        openButton.addEventListener('click', function(e) {
          e.preventDefault();
          openModal(modal)
        })
      })

      closeButton.addEventListener('click', function() {
        closeModal(modal)
      })

      background.addEventListener('click', function() {
        closeModal(modal)
      })
    })
  }

  window.SD_OpenModal = openModal
  window.SD_CloseModal = closeModal
})
