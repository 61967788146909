window.addEventListener("load",  function() {
  const header = document.getElementById("site-header");

  function burgerMenu() {
    const fullScreenMenu = document.getElementById("full-screen-menu");
    const fullScreenMenuToggle = document.getElementById("full-screen-menu-toggle");
    let scrollPosition = 0;
  
    // so when the menu is open a user cannot scroll the body
    function highjackScroll() {
      window.scrollTo(0, scrollPosition);
    }
    
    if (fullScreenMenuToggle) {
      fullScreenMenuToggle.addEventListener('click', function () {
        header.classList.toggle('open');
        fullScreenMenu.classList.toggle('open');
        scrollPosition = window.scrollY;
        
        if (fullScreenMenu.classList.contains('open')) {
          window.addEventListener('scroll', highjackScroll);
        }else {
          window.removeEventListener('scroll', highjackScroll);
        }
      });
    }
  }

  function fixOnScroll() {
    if (window.scrollY > 0) {
      header.classList.add('scrolled')
    }

    window.addEventListener('scroll', function() {
      if (window.scrollY > 0) {
        header.classList.add('scrolled')
      } else {
        header.classList.remove('scrolled')
      }
    });
  }

  burgerMenu()
  fixOnScroll()

});