// https://github.com/Alhadis/Accordion
import { Accordion } from "accordion";

window.addEventListener("load",  function() {
  var els = document.getElementsByClassName("accordion");
  
  if (els) {
    [...els].forEach((el)=> {
      const allowMultipleOpen = el.attributes['allow-multiple-open'] && el.attributes['allow-multiple-open'].value === 'true'

      new Accordion(el, {
        noAria: false,
        onToggle: function (target) {
          if (!allowMultipleOpen) {
            target.accordion.folds.forEach((fold) => {
              if (fold !== target) fold.open = false;
            });
          }
        }
      });
      el.classList.add('intialised')
    })

  }
});